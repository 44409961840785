import styled, { css } from "styled-components";
import { IBoxImageSettings } from "./BoxImage.types";
import composeCss, {
  chooseHorizontalAlignValue,
} from "@ecp-boxes/settingsPatterns/composeCss";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";

export const generateBoxImageStyles = ({
  $settings,
  theme,
  $height,
}: {
  $settings: IBoxImageSettings["$settings"];
  theme: IBoxImageSettings["theme"];
  $height: number;
}) => {
  const cssStyles = css`
    position: relative;
    display: flex;
    padding: ${composeCss.padding($settings.padding)};
    margin: ${composeCss.margin($settings.margin)};
    justify-content: ${chooseHorizontalAlignValue($settings.contentAlignment)};

    .svg {
      background-color: ${convertColorIdToHex(
        $settings.imageColor.color,
        theme.colorPalette
      )};

      width: 100%;
      height: ${$height}px;
      mask: url(${$settings.imageUrl}) no-repeat ${$settings.contentAlignment} /
        contain;
      -webkit-mask: url(${$settings.imageUrl}) no-repeat
        ${$settings.contentAlignment} / contain;
      object-fit: ${$settings.imagePlacement ? "contain" : "cover"};
      opacity: ${$settings.imageOpacity / 100};
      ${composeCss.hoverEffect($settings.imageHover)}

      &:hover {
        background-color: ${convertColorIdToHex(
          $settings.imageColor.hoverColor,
          theme.colorPalette
        )};
      }
      @media ((hover: none) or (hover: hover)) and (pointer: coarse) {
        &:hover {
          background-color: ${convertColorIdToHex(
            $settings.imageColor.color,
            theme.colorPalette
          )};
        }
      }
    }

    .imageContainer {
      overflow: hidden;
      ${composeCss.hoverEffect($settings.imageHover)}

      img {
        height: ${$height}px !important;
      }

      width: 100%;
    }
  `;

  return cssStyles;
};

export const StyledBoxImage = styled.div<
  IBoxImageSettings & { $height: number }
>((props) => generateBoxImageStyles(props));

export const StyledHrefWrapper = styled.div`
  .box-image-href {
    height: 100%;
    position: relative;
    width: 100%;
    flex-shrink: 1;
  }
`;
